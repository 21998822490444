import React, { memo, useCallback, useContext } from 'react';

import BatchContext from '@components/batch/batch-upload-edit/batch-context';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import './content-header.scss';

const ContentHeader = () => {
  const { batch: { rows }, rowId, rowIndex, skipped, setSkipped } = useContext(BatchContext);

  const onToggleClick = useCallback(event => {
    event.stopPropagation();
  }, []);

  const onToggleChange = useCallback(() => {
    setSkipped((prev) => {
      const updated = {...prev};
      updated[rowId] = !updated[rowId];
      return updated;
    });
  }, [rowId, setSkipped]);

  return (
    <div styleName="batch-upload-edit-content-header">
      <div styleName="header-title">
        {rowIndex + 1} of {rows?.length}
      </div>
      <div styleName="header-toggle-container">
        <FormControlLabel
          control={
            <Switch
              onClick={onToggleClick}
              onChange={onToggleChange}
              checked={skipped[rowId] === true}
              color="primary"
            />
          }
          label={<span styleName="header-toggle">Skip this entity</span>}
        />
      </div>
    </div>
  );
};

export default memo(ContentHeader);

