import React, { memo, useCallback, useContext } from 'react';

import BatchContext from '@components/batch/batch-upload-edit/batch-context';
import Info from '@components/entity/info';

import './content-form.scss';

const ContentForm = () => {
  return (
    <div styleName="batch-upload-edit-content-form">
      <Info />
    </div>
  );
};

export default memo(ContentForm);

