import React from 'react';

const initialState = {
  conflicts: null,
  data: null,
  filter: null,
  filterEntities: null,
  filteredEntities: null,
  getItemId: null,
  getOpenConflicts: null,
  getOpportunities: null,
  getPendingConflicts: null,
  getResolvedConflicts: null,
  groups: null,
  isSelected: false,
  highlightGeometry: true,
  loading: false,
  mainEntity: null,
  nonActionableOverlappingEntityTypes: null,
  onMapLoad: () => {},
  opportunities: null,
  segments: null,
  selectEntity: null,
  selectedSegmentIds: null,
  setFilter: () => {},
  setHighlightGeometry: () => {},
  setScrolled: () => {}
};

const OverlapContext = React.createContext(initialState);

export default OverlapContext;
