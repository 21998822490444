/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect';
import { replace } from 'connected-react-router';
import AuthenticatedLayout from './components/auth/layouts/authenticated';
import NotAuthenticatedLayout from './components/auth/layouts/not-authenticated';
import AdminDetails from './components/admin/details';
import BatchUploadEdit from './components/batch/batch-upload-edit';
import EntityDetails from './components/entity/details';
import PortalDetails from './components/portal/details';
import PortalDashboard from './components/portal/dashboard';
import PublicLayout from './components/auth/layouts/public';
import NotFoundPage from './components/not-found-page';
import NewDashboard from './components/new-dashboard';
import Details from './components/details';
import Calendar from './components/calendar';
import Login from './components/auth/login';
import TokenAuth from './components/auth/token-auth';
import Logout from './components/auth/logout';
import PasswordRecovery from './components/auth/password-recovery';
import Map from './components/map';
import PrintMap from './components/print-map';
import Cycle from './components/workflow/cycle';
import OverlapDetail from './components/overlap';
import NewDashboardDataTable from './components/new-dashboard/data-table';
import Portal from '@components/portal';
import Settings from './components/settings';
import SettingsProfile from './components/settings/profile';
import SettingsEmail from './components/settings/email';
import SettingsEmailDetails from './components/settings/email/details';
import SettingsBatch from './components/settings/batch';
import TimeLayout from './components/settings/time';
import SettingsNotifications from './components/settings/notifications';
import DashboardPortal from './components/dashboardportal';
import ActivityLogs from './components/activity-logs';
import Group from './components/group';
import Gantt from './components/group/gantt';
import PublicDetails from './components/public/details';
import PublicDetailsSuccess from './components/public/success';
import { authSelector } from '@utils/auth-utils';
import { isPCCO, getAgencyId } from '@utils/permission-utils';
import { withRouter } from '@utils/router-utils';
import ComponentZoo from '@components/dev/zoo';

// Redirects to /login by default:
const userIsAuthenticated = connectedReduxRedirect({
  authenticatedSelector: authSelector,
  redirectPath: '/login',
  redirectAction: replace,
  wrapperDisplayName: 'UserIsAuthenticated'
});

const Authenticated = withRouter(userIsAuthenticated(props => props.children));

// Routes for authenticated users:
const ProtectedRoute = ({ component: Component, ...other }) => (
  <Route {...other} render={props => (
    <Authenticated {...props}>
      <AuthenticatedLayout {...props}>
        <Component {...props} />
      </AuthenticatedLayout>
    </Authenticated>
  )} />
);

const PortalDashboardRoute = ({ component: Component, ...other }) => (
  <Route {...other} render={props => (
    <Authenticated {...props}>
      <AuthenticatedLayout {...props}>
        <PortalDashboard {...props}>
          <Component {...props} />
        </PortalDashboard>
      </AuthenticatedLayout>
    </Authenticated>
  )} />
);

const NewDashboardRoute = ({ component: Component, ...other }) => (
  <Route {...other} render={props => (
    <Authenticated {...props}>
      <AuthenticatedLayout {...props}>
        <NewDashboard {...props}>
          <Component {...props} />
        </NewDashboard>
      </AuthenticatedLayout>
    </Authenticated>
  )} />
);

// This route is for the agency listing page, if the user has no permissions
// to see all agencies, he'll be redirected to the agency details URL.
// It would be a better solution if we don't display the "Agencies" menu option
// for these users, display a "Agency" option instead.
const AgencyDashboardRoute = ({ component: Component, ...other }) => {
  const newOther = { ...other };
  let NewComponent = Component;
  if (!isPCCO()) {
    // Non-PCCO users must not see the agency listing page, thus
    // display the user's agency details page.
    newOther.computedMatch.params = { dataType: 'agency', dataId: getAgencyId() };
    newOther.computedMatch.path = '/agency/:id/:edit?';
    newOther.path = '/agency/:id/:edit?';
    NewComponent = PortalDetails;
  } else {
    newOther.computedMatch.params = { dataType: 'agency' };
    newOther.computedMatch.path = '/people/:dataType(agency)';
    newOther.path = '/people/:dataType(agency)';
  }

  return (
    <Route {...newOther} render={props => (
      <Authenticated {...props}>
        <AuthenticatedLayout {...props}>
          <Portal {...props}>
            <NewComponent {...props} />
          </Portal>
        </AuthenticatedLayout>
      </Authenticated>
    )} />
  );
};

const DetailsRoute = ({ component: Component, ...other }) => (
  <Route {...other} render={props => (
    <Authenticated {...props}>
      <AuthenticatedLayout {...props}>
        <Details {...props}>
          <Component {...props} />
        </Details>
      </AuthenticatedLayout>
    </Authenticated>
  )} />
);

const EmbeddedDetailsRoute = ({ component: Component, ...other }) => (
  <Route {...other} render={props => (
    <Authenticated {...props}>
      <AuthenticatedLayout {...props}>
        <NewDashboard {...props}>
          <Details {...props}>
            <Component {...props} />
          </Details>
        </NewDashboard>
      </AuthenticatedLayout>
    </Authenticated>
  )} />
);

const SettingsRoute = ({ component: Component, ...other }) => (
  <Route {...other} render={props => (
    <Authenticated {...props}>
      <AuthenticatedLayout {...props}>
        <Settings {...props}>
          <Component {...props} />
        </Settings>
      </AuthenticatedLayout>
    </Authenticated>
  )} />
);

// Routes that don't require authentication (the only difference between them is the layout component):
const OpenRoute = ({ component: Component, ...other }) => (
  <Route {...other} render={props => (
    <NotAuthenticatedLayout {...props}>
      <Component {...props} />
    </NotAuthenticatedLayout>
  )} />
);

const PublicRoute = ({ component: Component, ...other }) => (
  <Route {...other} render={props => (
    <PublicLayout {...props}>
      <Component {...props} />
    </PublicLayout>
  )} />
);

const Routes = ({ defaultUrl }) => (
  <Switch>
    <Redirect exact from="/" to={defaultUrl} />
    <Redirect exact from="/list" to="/library" />
    <Redirect exact from="/workflow" to="/workflow/activity/owner" />
    <Redirect exact from="/admin" to="/admin/data/workflow" />
    <Redirect exact from="/overlap/:subType/:dataId" to="/overlap/:dataId" />

    <PublicRoute path="/publicform/:dataType/success" component={PublicDetailsSuccess} />
    <PublicRoute path="/publicform/:dataType" component={PublicDetails} />

    <OpenRoute path="/auth/:token?/:userId?/:timestamp?" component={TokenAuth} />
    <OpenRoute path="/login" component={Login} />
    <OpenRoute path="/logout" component={Logout} />
    <OpenRoute path="/password/:userPk?/:token?/:onboarding?" component={PasswordRecovery} />

    <ProtectedRoute path="/calendar" component={Calendar} />
    <ProtectedRoute path="/:dataType(cycle)/:id/task/new/:taskTypeId" component={Cycle} />
    <ProtectedRoute path="/:dataType(cycle)/:id/task/:taskId/activity/:taskActivityId" component={Cycle} />
    <ProtectedRoute path="/:dataType(cycle)/:id/task/:taskId" component={Cycle} />
    <ProtectedRoute path="/:dataType(cycle)/:id" component={Cycle} />
    <ProtectedRoute path="/group/gantt/:groupType/:dataId" component={Gantt} />
    <ProtectedRoute path="/group/:groupType/:dataId" component={Group} />
    <ProtectedRoute path="/logs/:app/:model/:dataId" component={ActivityLogs} />
    <ProtectedRoute path="/map/print" component={PrintMap} />
    <ProtectedRoute path="/map" component={Map} />
    <ProtectedRoute path="/overlap/:dataId" component={OverlapDetail} />
    <ProtectedRoute path="/library/:dataType?/:subType?" component={Portal} />
    {process.env.NODE_ENV === 'development' &&
      <ProtectedRoute path="/dev/zoo" component={ComponentZoo} />
    }

    <ProtectedRoute path="/workflow/:dataType?/:subType?" component={Portal} />
    <AgencyDashboardRoute path="/people/:dataType(agency)" component={Portal} />
    <ProtectedRoute path="/people/:dataType(user)" component={Portal} />
    <ProtectedRoute path="/:dataType(batch-upload-edit)/:id/:rowId?" component={BatchUploadEdit} />
    <PortalDashboardRoute path="/:dataType(agency)/:id/:edit?" component={PortalDetails} />
    <PortalDashboardRoute path="/:dataType(batch)/:id" component={PortalDetails} />
    <PortalDashboardRoute path="/:dataType(user)/:id/:edit?" component={PortalDetails} />
    <NewDashboardRoute path="/admin/data/:dataType" component={NewDashboardDataTable} />
    <EmbeddedDetailsRoute path="/admin/:dataType/:id" component={AdminDetails} />

    <SettingsRoute path="/settings/profile" component={SettingsProfile} />
    <SettingsRoute path="/settings/email/:type" component={SettingsEmailDetails} />
    <SettingsRoute path="/settings/email" component={SettingsEmail} />
    <SettingsRoute path="/settings/notifications" component={SettingsNotifications} />
    <SettingsRoute path="/settings/batch" component={SettingsBatch} />
    <SettingsRoute path="/settings/time" component={TimeLayout} />

    <ProtectedRoute path="/:portalType(dashboard)/" component={DashboardPortal} />
    {/* We can use the DashboardPortal component to build different types of dashboards: */}
    {/* <ProtectedRoute path="/:portalType(budgeting)/" component={DashboardPortal} /> */}

    <DetailsRoute path="/:dataType/new" component={EntityDetails} />
    <DetailsRoute path="/:dataType/:id/:action" component={EntityDetails} />
    <DetailsRoute path="/:dataType/:id" component={EntityDetails} />

    <Route path="*" component={NotFoundPage} />
  </Switch>
);

const RoutesWrapper = () => {
  const { defaultUrl } = useSelector(state => state.config);
  return <Routes defaultUrl={defaultUrl} />;
};

export default RoutesWrapper;
