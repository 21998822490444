/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { uploadBatch } from '@actions/batch-actions';
import { fetchLayerConfig } from '@actions/layers-actions';
import { colors, dotmapsGray } from '@constants/colors';
import { dialogStyles } from '@constants/mui-theme';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Icon, IconButton, Select } from '@mui';
import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';
import FileArea from '@shared/files/file-area';
import { canAccessEntity } from '@utils/permission-utils';
import { sortByField } from '@utils/data-types-utils';
import './batch-upload-form.scss';

const { MenuItem } = Select;

const defaultState = {
  layerType: null,
  entityType: null,
  typeSelected: null,
  toUpload: null,
  uploadStarted: false,
  progress: 0,
  fileValidationError: false,
  uploadErrored: false
};

// Restrict file uploads to 8 mb:
const MAX_UPLOAD_SIZE = 8388608;

class BatchUploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
      open: props.open === null ? false : props.open
    };
  }
  UNSAFE_componentWillMount() {
    if (R.isEmpty(this.props.layers)) {
      this.props.fetchLayerConfig();
    }
  }
  componentDidUpdate = prevProps => {
    const {open: controlledOpen} = this.props;
    const {open: prevControlledOpen} = prevProps;
    if (controlledOpen === null) {
      return;
    }
    if (controlledOpen !== prevControlledOpen) {
      if (controlledOpen === true && this.state.open === false) {
        this.handleOpen();
      }
      if (controlledOpen === false && this.state.open === true) {
        this.handleClose();
      }
    }
  };

  handleUploadClick = () => {
    if (this.state.toUpload) {
      this.setState({ uploadStarted: true }, () => {
        const { entityType, layerType, toUpload } = this.state;
        const upload = {
          active: true,
          file: toUpload,
          entityType,
          layerType,
          status: 0
        };
        this.props.uploadBatch(upload, this.handleProgress.bind(this), this.handleFail.bind(this));
      });
    }
  };
  handleFileClear = () => {
    this.setState({
      fileValidationError: false,
      toUpload: null
    });
  };
  handleFileChange = file => {
    if (file) {
      this.setState({
        toUpload: file,
        fileValidationError: file.size > MAX_UPLOAD_SIZE
      });
    } else if (this.state.toUpload) {
      this.setState({ toUpload: null });
    }
  };
  onLayerChange = event => {
    this.setState({ layerType: event.target.value });
  };
  onTypeChange = event => {
    this.setState({
      entityType: event.target.value,
      typeSelected: event.target.value
    });
  };
  entityTypeRenderer = (selected, values) => {
    if (values?.length === 0 || !selected) {
      return 'Select';
    }
    return this.getEntityTypes().filter(entityType => entityType.name === selected)[0].label;
  };
  layerRenderer = (selected, values) => {
    if (values?.length === 0 || !selected) {
      return 'Select a layer';
    }
    return this.getLayers().find(layer => layer.name === selected).label;
  };
  handleOpen = () => {
    this.setState({
      ...defaultState,
      open: true
    });
  };
  handleClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
    this.setState({ open: false });
  };
  handleProgress(event) {
    const progress = Math.floor((event.loaded / event.total) * 100);
    this.setState({ progress }, () => {
      if (progress === 100) {
        this.setState({ uploadStarted: false, open: false });
      }
    });
  }
  // eslint-disable-next-line no-unused-vars
  handleFail(error) {
    this.setState({uploadErrored: true});
  }
  getLayers = () => {
    const layers = [];
    for (const name in this.props.layers) {
      if (Object.prototype.hasOwnProperty.call(this.props.layers, name)) {
        // Don't list Zoning and Streets Use Permits for now:
        if (name !== 'zoning' && name !== 'street_use_permits') {
          const layer = this.props.layers[name];
          layers.push({
            label: layer.label,
            name: layer.name,
            // Disabled all layers but Hubs (for now),
            // this means, we can only upload Hubs json files.
            disabled: layer.name !== 'hubs'
          });
        }
      }
    }
    return layers;
  };
  getEntityTypes = () => {
    const { entityTypes } = this.props;
    const entityList = sortByField('order')(R.values(R.filter(et => et.order > 0, entityTypes)));
    const list = [
      ...(Object.keys(entityList).map(key => (
        { name: entityList[key].name, label: entityList[key].label}
      ))),
      {
        name: 'layer',
        label: 'City Layer'
      }
    ];
    return list;
  };
  render() {
    const {open: controlledOpen = null} = this.props;
    if (!canAccessEntity(null, 'batchfile', 'add')) {
      return null;
    }
    const { typeSelected, open, uploadStarted, uploadErrored } = this.state;
    let actions = null;
    if (open && !uploadStarted && !uploadErrored) {
      actions = (
        <DialogActions
          disabledSubmit={!this.state.toUpload || !this.state.typeSelected || this.state.fileValidationError}
          onCancel={this.handleClose}
          onSubmit={this.handleUploadClick}
          submitLabel="UPLOAD"
        />
      );
    }
    if (uploadErrored) {
      actions = (
        <DialogActions
          onCancel={this.handleClose}
          onSubmit={this.handleOpen}
          submitLabel="TRY AGAIN"
        />
      );
    }
    return (
      <Fragment>
        {controlledOpen === null &&
          <IconButton
            id="dataTableBatchUploadButton"
            onClick={this.handleOpen}
            customIcon={<Icon color={colors.neutral.white}>add</Icon>}
            tooltip="Upload Batch"
          >
            <Icon color={colors.neutral.white}>add</Icon>
          </IconButton>
        }
        <Dialog
          actions={actions}
          onClose={this.handleClose}
          open={this.state.open || false}
          title={open && !uploadStarted && !uploadErrored ? 'Upload Batch' : ''}
        >
          {!uploadStarted && !uploadErrored &&
            <div>
              <div styleName="subheader">
                Select the data you want to create or update in batches:
              </div>
              <div styleName="section section-select">
                <Select
                  displayEmpty
                  onChange={this.onTypeChange}
                  renderValue={values => this.entityTypeRenderer(typeSelected, values)}
                  value={typeSelected}
                >
                  {this.getEntityTypes().map(entityType => (
                    <MenuItem
                      key={entityType.name}
                      value={entityType.name}
                    >
                      {entityType.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div styleName="section section-select" style={{ display: this.state.typeSelected === 'layer' ? 'block' : 'none'}}>
                <Select
                  disabled={this.state.typeSelected !== 'layer'}
                  displayEmpty
                  onChange={this.onLayerChange}
                  renderValue={values => this.layerRenderer(this.state.layerType, values)}
                  value={this.state.layerType}
                >
                  {this.getLayers().map(layer => (
                    <MenuItem
                      disabled={layer.disabled}
                      key={layer.name}
                      value={layer.name}
                    >
                      {layer.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div styleName="section">
                <div styleName="section-header">
                  Choose a file:
                </div>
                <div styleName="section-row">
                  <FileArea
                    id="batch-upload-input"
                    accept={this.state.typeSelected === 'layer' ? 'application/json,application/geo+json,.geojson' : 'text/csv'}
                    hasError={this.state.fileValidationError}
                    onFileClear={this.handleFileClear}
                    onFileSelect={this.handleFileChange}
                  />
                </div>
              </div>
              {this.state.fileValidationError && this.state.toUpload &&
                <div styleName="footer file-upload-error">
                  The file ({(this.state.toUpload.size / 1048576).toFixed(0)} MB) exceeds the limit.
                </div>
              }
              {!this.state.fileValidationError && (
                <div styleName="footer">
                  Acceptable file type:&nbsp;
                  {this.state.typeSelected !== 'layer' && 'CSV'}
                  {this.state.typeSelected === 'layer' && 'JSON'}
                  &nbsp;&nbsp;&bull;
                  Max file size: 8 MB
                </div>
              )}
            </div>
          }
          {uploadStarted && !uploadErrored &&
            <div styleName="loading-container">
              <CircularProgress size={66} thickness={6} />
              <div style={dialogStyles.content.title}>
                Uploading...
              </div>
            </div>
          }
          {uploadErrored &&
            <div styleName="error-container">
              <ErrorOutlineIcon htmlColor={dotmapsGray} style={{ fontSize: '4.5rem' }} />
              <div style={dialogStyles.content.title}>
                Upload Failed
              </div>
            </div>
          }
        </Dialog>
      </Fragment>
    );
  }
}

BatchUploadForm.propTypes = {
  entityTypes: PropTypes.object,
  fetchLayerConfig: PropTypes.func,
  layers: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  uploadBatch: PropTypes.func
};

const mapStateToProps = state => {
  const { dataTypes: { map_type }, layers } = state;
  return { entityTypes: map_type, layers };
};

const mapDispatchToProps = {
  fetchLayerConfig,
  uploadBatch
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(BatchUploadForm);
