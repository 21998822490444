/* eslint-disable react/jsx-no-bind */
import React, { memo, useContext } from 'react';

import PropTypes from 'prop-types';

import BatchContext from '@components/batch/batch-upload-edit/batch-context';

import CustomChip from '@shared/custom-chip';

import './drawer-filters.scss';

const FilterChip = ({ icon, id, label }) => {
  const { filter, setFilter } = useContext(BatchContext);
  return (
    <div styleName="filter-chip">
      <CustomChip
        clickable
        colors={{
          background: filter === id ? 'rgba(64, 110, 227, 0.12)' : 'rgba(0, 0, 0, 0.08)',
          foreground: filter === id ? '#406EE3' : '#616161'
        }}
        icon={icon}
        label={label}
        onClick={() => setFilter(id)}
      />
    </div>
  );
};

FilterChip.propTypes = {
  icon: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string
};

export default memo(FilterChip);
