import React, { memo, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import Drawer from '@components/batch/batch-upload-edit/drawer';
import Content from '@components/batch/batch-upload-edit/content';
import BatchContext from '@components/batch/batch-upload-edit/batch-context';

import TopBar from '@components/details/top-bar';

import { getBatch } from '@utils/batch-utils';

import './batch-upload-edit.scss';

const BatchUploadEdit = () => {
  const { id: fileId, rowId } = useParams();
  const [filter, setFilter] = useState('all');
  const batch = useMemo(() => getBatch(fileId), [fileId]);
  const [skipped, setSkipped] = useState({});

  const rowIndex = useMemo(() => {
    return batch?.rows?.findIndex(item => item.id === rowId);
  }, [rowId, batch?.rows]);

  const contextValue = {
    batch,
    fileId,
    filter,
    rowId,
    rowIndex,
    setFilter,
    setSkipped,
    skipped
  };

  return (
    <BatchContext.Provider value={contextValue}>
      <div styleName="batch-upload-edit-container">
        <TopBar />
        <div styleName="batch-upload-edit-content">
          <Drawer />
          <Content />
        </div>
      </div>
    </BatchContext.Provider>
  );
};

export default memo(BatchUploadEdit);
