import React, { memo, useMemo, useContext } from 'react';

import { colors } from '@constants/colors';

import BatchContext from '@components/batch/batch-upload-edit/batch-context';
import {
  FILTER_ALL,
  FILTER_ERRORS,
  FILTER_SKIPPED
} from '@components/batch/batch-upload-edit/constants';
import FilterChip from '@components/batch/batch-upload-edit/drawer-filter-chip';

import BlockIcon from '@material-ui/icons/Block';
import InfoIcon from '@material-ui/icons/Info';

import { getUniqueErrors } from '@utils/batch-utils';

import './drawer-filters.scss';

const DrawerFilters = () => {
  const { batch: { errors }, skipped } = useContext(BatchContext);
  const errorCount = useMemo(() => getUniqueErrors(errors).size, [errors]);
  const errorLabel = useMemo(() => `${errorCount} Errors`, [errorCount]);
  const skippedCount = useMemo(() => Object.values(skipped).filter(item => item === true).length, [skipped]);
  const skippedLabel = useMemo(() => `${skippedCount} Skipped`, [skippedCount]);
    
  return (
    <div styleName="batch-upload-edit-drawer-filters-container">
      <FilterChip id={FILTER_ALL} label="All" />
      <FilterChip
        id={FILTER_ERRORS}
        icon={<InfoIcon style={{ color: colors.utility.muiError }} />}
        label={errorLabel}
      />
      <FilterChip
        id={FILTER_SKIPPED}
        icon={<BlockIcon style={{ color: '#ED6C02' }} />}
        label={skippedLabel}
      />
    </div>
  );
};

export default memo(DrawerFilters);
