/* eslint-disable react/jsx-no-bind */
import React, { Fragment, memo, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';

import OverlapContext from '@components/overlap/overlap-context';

import * as colors from '@constants/colors';
import { markerConfig } from '@constants/component-configs';

import { Marker } from '@react-google-maps/api';

import MapFigure from '@shared/map-figure';

import buffer from '@turf/buffer';
import { multiLineString } from '@turf/helpers';
import lineIntersect from '@turf/line-intersect';
import lineOverlap from '@turf/line-overlap';

import { getEntityMarker } from '@utils/icon-utils';
import {
  parseCenterTuple,
  zipCoordinatePair
} from '@utils/map-utils';

const MIDDLE_OPTIONS = { strokeWeight: 6, zIndex: 208, strokeColor: 'white' };
const OUTER_OPTIONS = { strokeWeight: 12, zIndex: 207, strokeOpacity: 0.6, strokeColor: colors.dotmapsOverlapLead };
const OVERLAPPING_OPTIONS = {
  fillColor: colors.dotmapsOverlapOverlapping,
  strokeColor: colors.dotmapsOverlapOverlapping,
  strokeOpacity: 0.6,
  strokeWeight: 22,
  zIndex: 220
};

const buildColor = color => ({
  fillColor: color,
  strokeColor: color
});

// Convert to a LineString for rendering:
const toLineString = points => {
  const coords = points[0].geometry.coordinates;
  return {
    coordinates: [coords, coords],
    type: 'LineString'
  };
};

const toMultiSegments = segments => {
  const shapes = [];
  segments.forEach(({ shape }) => {
    if (shape.type === 'Point') {
      shapes.push([shape.coordinates, shape.coordinates]);
    } else
      if (shape.type === 'Polygon') {
        shape.coordinates.map(coords => shapes.push(coords));
      } else
        if (shape.type === 'LineString') {
          shapes.push(shape.coordinates);
        }
  });

  return multiLineString(shapes);
};

const MapSegment = ({ segment }) => {
  const {
    highlightGeometry,
    selectEntity,
    selectedSegmentIds
  } = useContext(OverlapContext);

  const onMarkerClick = useCallback(position => {
    const center = zipCoordinatePair([position.latLng.lng(), position.latLng.lat()]);
    selectEntity(segment.entity, center);
  }, [segment.entity, selectEntity]);

  const active = useMemo(
    () => includes(selectedSegmentIds, segment.id),
    [segment.id, selectedSegmentIds]
  );

  const options = useMemo(() => {
    const common = {
      fillColor: colors.dotmapsSegmentColor,
      strokeColor: colors.dotmapsSegmentColor,
      strokeWeight: active ? 4 : 5,
      zIndex: active ? 211 : 10
    };
    const { isLead, isOpen, isOpportunity, isPending, isResolved } = segment.overlapType;
    if (isLead) {
      return { ...common, ...buildColor(colors.dotmapsOverlapLead), zIndex: 210 };
    }
    if (isOpen) {
      return { ...common, ...buildColor(colors.dotmapsOverlapOpen) };
    }
    if (isOpportunity) {
      return { ...common, ...buildColor(colors.dotmapsOverlapOpportunity) };
    }
    if (isPending) {
      return { ...common, ...buildColor(colors.dotmapsOverlapPending) };
    }
    if (isResolved) {
      return { ...common, ...buildColor(colors.dotmapsOverlapResolved) };
    }
    return { ...common };
  }, [active, segment.overlapType]);

  const mode = useMemo(() => {
    const { isOpen, isOpportunity, isPending, isResolved } = segment.overlapType;
    if (isOpen) {
      return 'conflict';
    }
    if (isOpportunity) {
      return 'opportunity';
    }
    if (isPending) {
      return 'pending';
    }
    if (isResolved) {
      return 'resolved';
    }
    return null;
  }, [segment.overlapType]);

  const icon = useMemo(() => {
    const { iconId, type_name } = segment.entity;
    return getEntityMarker(
      segment.agency_type,
      type_name,
      iconId,
      active,
      mode
    );
  }, [active, mode, segment.agency_type, segment.entity]);

  // Get the overlapping shape between this segment and the main entity one.
  const getOverlappingShape = useCallback(() => {
    const { mainEntitySegments } = segment;
    const multiSegment1 = toMultiSegments(mainEntitySegments);
    const multiSegment2 = toMultiSegments([segment]);

    // Check if an exact line match exists:
    const geometries = lineOverlap(multiSegment1, multiSegment2, { tolerance: 0.000001 }).features;
    if (geometries.length > 0) {
      return geometries[0].geometry;
    }

    // Else check for point intersections:
    const points = lineIntersect(multiSegment1, multiSegment2).features;
    if (points.length > 0) {
      return toLineString(points);
    }

    // It's not matching anything, buffer points and try with point intersections again:
    const bufferedMS1 = buffer(multiSegment1, 0.1, { units: 'meters' });
    const bufferedMS2 = buffer(multiSegment2, 0.1, { units: 'meters' });
    const bufferedPoints = lineIntersect(bufferedMS1, bufferedMS2).features;
    if (bufferedPoints.length > 0) {
      return toLineString(bufferedPoints);
    }

    return null;
  }, [segment]);

  const renderFigure = useCallback((id, shape, figureOptions) => (
    <MapFigure
      key={`f-${id}`}
      onClick={onMarkerClick}
      options={figureOptions}
      shape={shape}
    />
  ), [onMarkerClick]);

  const renderedSegment = useMemo(() => {
    const { shape } = segment;
    if (active) {
      // Render 'glow' effect for overlap active segments:
      const lines = [
        renderFigure(segment.id, shape, {...options}),
        renderFigure(`${segment.id}-m`, shape, {...options, ...MIDDLE_OPTIONS}),
        renderFigure(`${segment.id}-o`, shape, {...options, ...OUTER_OPTIONS})
      ];
      // Render "overlapping geometry":
      const { isLead } = segment.overlapType;
      if (!isLead && highlightGeometry) {
        // eslint-disable-next-line max-depth
        const overlappingShape = getOverlappingShape();
        if (overlappingShape) {
          lines.push(renderFigure(`${segment.id}-l`, overlappingShape, {...options, ...OVERLAPPING_OPTIONS}));
        }
      }
      return lines;
    }
    return renderFigure(segment.id, shape, options);
  }, [active, getOverlappingShape, highlightGeometry, options, renderFigure, segment]);

  const renderedMarker = useMemo(() => {
    if (icon?.url && segment.showMarker && segment.center) {
      const segmentCenter = parseCenterTuple(segment.center);
      const key = `m-${segment.id}`;
      return (
        <Marker
          icon={icon}
          key={key}
          onClick={onMarkerClick}
          position={segmentCenter}
          options={markerConfig.options}
        />
      );
    }
    return null;
  }, [icon, onMarkerClick, segment.center, segment.id, segment.showMarker]);

  return (
    <Fragment>
      {renderedMarker}
      {renderedSegment}
    </Fragment>
  );
};

MapSegment.propTypes = {
  segment: PropTypes.object
};

export default memo(MapSegment);
