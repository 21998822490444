/* eslint-disable id-length */
import { v4 as uuid } from 'uuid';

export const getBatch = fileId => JSON.parse(sessionStorage.getItem(fileId));

// Format the row_nums for display, finding sequences.
export const formatRowNums = rowNums => {
  if (!Array.isArray(rowNums)) {
    return rowNums.toString();
  }

  // Find continuous sequences.
  const sequences = [];
  let currentSeq = [rowNums[0]];

  for (let i = 1; i < rowNums.length; i++) {  // eslint-disable-line id-length, no-plusplus
    if (rowNums[i] === currentSeq[currentSeq.length - 1] + 1) {
      currentSeq.push(rowNums[i]);
    } else {
      sequences.push(currentSeq);
      currentSeq = [rowNums[i]];
    }
  }
  sequences.push(currentSeq);

  // Format sequences
  return sequences.map(seq =>
    seq.length > 1 ? `${seq[0]}-${seq[seq.length - 1]}` : seq[0].toString()
  ).join(', ');
};

// Returns a set with the unique line numbers in the errors object.
export const getUniqueErrors = errors => new Set(errors.map(error => error.line_number));

// Convert the data returned by the batch upload API into a list of rows to display in the UI.
const processBatchRows = fileContent => {
  const { rows } = fileContent;
  const errors = getUniqueErrors(fileContent.errors);

  // Convert into an array of rows:
  const entries = Object.entries(rows).map(([key, value]) => ({
    ...value,
    display_row_nums: formatRowNums(value.row_nums),
    hasErrors: value.row_nums.some(number => errors.has(number)),
    id: key
  }));

  // Sort the array based on the first row number found in 'row_nums'.
  return entries.sort((a, b) => {
    // Ensure row_nums exists and has at least one element:
    const rowA = a.row_nums?.[0] ?? Infinity;
    const rowB = b.row_nums?.[0] ?? Infinity;
    return rowA - rowB;
  });
};

// Filter rows returning only the ones with errors:
export const filterErrorRows = (rows, errors) => {
  const numbers = getUniqueErrors(errors);
  return rows.filter(row => row.row_nums.some(number => numbers.has(number)));
};

export const saveBatch = (entity, file, fileContent) => {
  const fileId = uuid();
  const rows = processBatchRows(fileContent);
  const data = { entity, errors: fileContent.errors, file, rows };
  sessionStorage.setItem(fileId, JSON.stringify(data));
  return { fileId, rows };
};
