/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useContext, useState } from 'react';
import DrawerFilters from '@components/overlap/drawer-filters';
import DrawerList from '@components/overlap/drawer-list';
import DrawerMainCard from '@components/overlap/drawer-main-card';
import OverlapContext from '@components/overlap/overlap-context';
import MapTray from '@components/map/tray';
import { paper } from '@constants/mui-theme';
import AddToGroupExcludeOverlapDialog from '@shared/dialogs/add-to-group-exclude-overlap-dialog';
import OverlapRevokeDialog from '@shared/dialogs/overlap-revoke-dialog';
import OverlapStatusDialog from '@shared/dialogs/overlap-status-dialog';
import { getItemHeight } from '@utils/overlap-utils';
import './drawer.scss';

const Drawer = () => {
  // Sets the sticky flag so the overlap dropdown item in the list becomes sticky while scrolling.
  const [sticky, setSticky] = useState(false);

  const { mainEntity, opportunities, setScrolled } = useContext(OverlapContext);

  const onScroll = useCallback(event => {
    setScrolled(true);
    setSticky(event.target.scrollTop > getItemHeight(mainEntity));
  }, [mainEntity, setScrolled, setSticky]);

  return (
    <div styleName="drawer-container" key="drawer" style={paper}>
      <div styleName="drawer-list" onScroll={onScroll}>
        <DrawerMainCard />
        <DrawerFilters sticky={sticky} />
        <DrawerList />
      </div>
      <MapTray />
      <AddToGroupExcludeOverlapDialog />
      <OverlapRevokeDialog />
      <OverlapStatusDialog mainEntity={mainEntity} opportunities={opportunities} />
    </div>
  );
};

export default Drawer;
