import React, { useCallback } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import DialogActions from '@shared/dialogs/dialog-actions';

const TopBarBatchUploadEditActions = () => {
  const dispatch = useDispatch();

  const onCancel = useCallback(
    () => {
      dispatch(push('/library/batch'));
    },
    [dispatch]
  );

  const onUpload = useCallback(
    () => {
      // TODO:
      // dispatch();
    },
    [dispatch]
  );

  return (
    <DialogActions
      onCancel={onCancel}
      onSubmit={onUpload}
      submitLabel="UPLOAD"
    />
  );
};

export default TopBarBatchUploadEditActions;
