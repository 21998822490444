import React, { memo } from 'react';

import ContentHeader from '@components/batch/batch-upload-edit/content-header';
import ContentForm from '@components/batch/batch-upload-edit/content-form';

import './content.scss';

const Content = () => (
  <div styleName="batch-upload-edit-content-container">
    <ContentHeader />
    <ContentForm />
  </div>
);

export default memo(Content);
