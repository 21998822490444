import React from 'react';

const initialState = {
  batch: null,
  fileId: null,
  filter: null,
  rowId: null,
  rowIndex: 0,
  setFilter: () => {},
  setSkipped: () => {},
  skipped: {}
};

const BatchContext = React.createContext(initialState);

export default BatchContext;
