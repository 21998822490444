import React, { memo, useContext, useMemo } from 'react';

import BatchContext from '@components/batch/batch-upload-edit/batch-context';
import {
  FILTER_ALL,
  FILTER_ERRORS,
  FILTER_SKIPPED
} from '@components/batch/batch-upload-edit/constants';
import DrawerRowItemCard from '@components/batch/batch-upload-edit/drawer-row-item-card';

import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';

import { filterErrorRows } from '@utils/batch-utils';

import './drawer-row-list.scss';

const DrawerRowList = () => {
  const { batch: { errors, rows }, filter, skipped } = useContext(BatchContext);

  const filteredRows = useMemo(() => {
    if (filter === FILTER_ALL) {
      return rows;
    }
    if (filter === FILTER_ERRORS) {
      return filterErrorRows(rows, errors);
    }
    if (filter === FILTER_SKIPPED) {
      return rows?.filter(row => skipped[row.id] === true);
    }
    return [];
  }, [errors, filter, rows, skipped]);

  if (filteredRows?.length === 0) {
    return (
      <div styleName="batch-upload-edit-drawer-row-list-container">
        <div styleName="row-list-empty">
          <div styleName="content">
            <div styleName="icon">
              <DnsOutlinedIcon htmlColor="#C2CBF5" style={{ fontSize: '6rem' }} />
            </div>
            <div styleName="message">No entities found</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div styleName="batch-upload-edit-drawer-row-list-container">
      <ul styleName="row-list-body">
        {filteredRows?.map((row, index) => <DrawerRowItemCard key={row.id} index={index + 1} row={row} />)}
      </ul>
    </div>
  );
};

export default memo(DrawerRowList);
