/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resendOnboardingEmail } from '@actions/auth-actions';
import { showConfirmationDialog } from '@actions/confirmation-actions';
import {
  confirmableDeactivateDashboardEntry,
  confirmableReactivateDashboardEntry
} from '@actions/dashboard-actions';
import { colors } from '@constants/colors';
import BlockIcon from '@material-ui/icons/Block';
import SendIcon from '@material-ui/icons/Send';
import UndoIcon from '@material-ui/icons/Undo';
import IconMenu from '@shared/icon-menu';
import IconMenuItem from '@shared/icon-menu-item';
import { isAdmin } from '@utils/permission-utils';

const UserActions = () => {
  const dispatch = useDispatch();
  const { is_active: isActive, id } = useSelector(state => state.dashboard.details?.user?.data || {});

  const deactivate = () => {
    dispatch(showConfirmationDialog(
      'Deactivate user',
      'Are you sure you want to deactivate this user?' +
      ' This user will no longer be able to log in or perform actions until reactivated.',
      confirmableDeactivateDashboardEntry('user', Number(id))
    ));
  };

  const reactivate = () => {
    dispatch(showConfirmationDialog(
      'Reactivate user',
      'Are you sure you want to reactivate this user?' +
      ' This user will regain access to the application and perform actions.',
      confirmableReactivateDashboardEntry('user', Number(id))
    ));
  };

  const resendOnboarding = () => {
    dispatch(resendOnboardingEmail(id));
  };

  // Only allow this functionality for admins:
  if (!isAdmin()) {
    return null;
  }

  const activeLabel = isActive ? <div style={{ color: colors.brand.red }}>Deactivate user</div> : 'Reactivate user';
  const activeCallback = isActive ? deactivate : reactivate;
  return (
    <IconMenu>
      <IconMenuItem icon={<SendIcon />} onClick={resendOnboarding}>Resend invite</IconMenuItem>
      <IconMenuItem icon={isActive ? <BlockIcon htmlColor={colors.brand.red} /> : <UndoIcon />}onClick={activeCallback}>
        {activeLabel}
      </IconMenuItem>
    </IconMenu>
  );
};

export default memo(UserActions);
